const config = {
  forwoodId: {
    APIGATEWAY: 'https://gryq6vrex0.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://0bg3j8qz6f.execute-api.ap-southeast-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'forwood-homepage-1.5.3',
    HOSTNAME: 'https://id.ausgrid.forwoodsafety.com',
    WEB_URL: 'https://homepage.ausgrid.forwoodsafety.com',
    CVC_ANDROID: 'https://play.google.com/store/apps/details?id=com.forwood.forwood_crm_2',
    CVC_IOS: 'https://apps.apple.com/vn/app/forwood-ccv/id1574565890',
    UPDATE_SETTING_ACCESS_ROLE: 'ForwoodIdAdmin',
    TRANSLATION_VARIANT: ''
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
